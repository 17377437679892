.about {
    min-height: fit-content;
    position: relative;
  }
  
  .about .line-styling {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .about .line-styling .style-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .about .line-styling .style-line {
    width: 95%;
    height: 5px;
    border-radius: 10px;
  }
  
  .about-body {
    display: grid;
    grid-template-columns: 60% 40%;
    padding-top: 8rem;
    height: fit-content;
    overflow-x: hidden;
  }
  
  .back-button {
    margin-top: 50px;
  }
  .about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.5;
    width: 90%;
    height: fit-content;
    overflow-x: hidden;
    margin-top: -50px;
  }
  
  .about-description > h2 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-family: var(--primaryFont);
  }
  
  .about-description > p {
    font-size: 1.25rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
  }
  
  .about-animation {
    margin-top: 15px;
    pointer-events: none;
    display: flex;
    justify-content: center;
  }
  .about-animation-center {
    margin-top: 15px;
    margin-left: 400px;
    margin-bottom: 15px;
    pointer-events: none;
    display: flex;
    justify-content: center;
  }
  
  .lcr-buttonContainer {
    margin-top: 2rem;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "var(--primaryFont)", sans-serif;
  }
  
  .about-description > h3 {
    margin-top: 70px;
    margin-bottom: 15px;
    font-size: 1.6rem;
    font-family: var(--primaryFont);
  }
  
  .about-description > h4 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-family: var(--primaryFont);
  }
  
  @media (min-width: 992px) and (max-width: 1380px) {
    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }
    .about-description > h3 {
      font-size: 1rem;
    }
  
    .about-description > h4 {
      font-size: 0.8rem;
    }
  
    .about-animation {
      max-width: 85%;
      max-height: 85%;
      pointer-events: none;
      display: flex;
      justify-content: center;
    }
  
    .back-button {
      margin-top: 20px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .about .line-styling {
      top: 20px;
    }
  
    .about-body {
      padding-top: 2rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-evenly;
    }
  
    .about-description {
      flex: 0.6;
      box-sizing: border-box;
      padding: 20px;
      margin-top: 20px;
    }
  
    .about-description > h2 {
      font-size: 3rem;
      margin-bottom: 20px;
    }
  
    .about-description > p {
      font-size: 1.1rem;
    }
  
    
    .back-button {
      padding-top: 2rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 2%;
    }
  }
  
  @media screen and (max-width: 800px) {
    .about .line-styling .style-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .about-description > h2 {
      align-self: center;
      margin-top: 50px;
    }
  
    .back-button {
      padding-top: 2rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 2%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .about .line-styling .style-circle {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    .about .line-styling .style-line {
      width: 93%;
      height: 3px;
      border-radius: 10px;
    }
  
    .about-description > h2 {
      font-size: 2.5rem;
      margin-top: 50px;
    }
  
    .about-description > p {
      font-size: 0.95rem;
    }
  
    .back-button {
      padding-top: 2rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 2%;
    }
  }
  