/* .live-demo-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background: #222;
    background-image: url('https://source.unsplash.com/1600x900/?landscape');
    font-size: 120%;
} */
.live-demo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background: #222;
    background-image: url('https://source.unsplash.com/1600x900/?landscape');
    font-size: 120%;
}


.live-demo-card {
    /* background: #000000d0;
    color: white;
    padding: 2em;
    border-radius: 30px;
    width: 100%;
    max-width: 420px;
    margin: auto; Center horizontally */
    background: #000000d0;
    color: white;
    padding: 2em;
    border-radius: 30px;
    width: 100%;
    max-width: 420px;
    margin: 1em;
}

.live-demo-search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.live-demo-button {
    margin: 0.5em;
    border-radius: 50%;
    border: none;
    height: 44px;
    width: 44px;
    outline: none;
    background: #7c7c7c2b;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.live-demo-search-bar {
    border: none;
    outline: none;
    padding: 0.4em 1em;
    border-radius: 24px;
    background: #7c7c7c2b;
    color: white;
    font-family: inherit;
    font-size: 105%;
    width: calc(100% - 100px);
}

.live-demo-button:hover {
    background: #7c7c7c6b;
}

.live-demo-temp {
    margin: 0;
    margin-bottom: 0.4em;
}

.live-demo-flex {
    display: flex;
    align-items: center;
}

.live-demo-description {
    text-transform: capitalize;
    margin-left: 8px;
}

.live-demo-loading {
    visibility: hidden;
    max-height: 20px;
    position: relative;
}

.live-demo-loading:after {
    visibility: visible;
    content: "Loading...";
    color: white;
    position: absolute;
    top: 0;
    left: 20px;
}
